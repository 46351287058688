import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/Container2"

const GenericPage = ({ data }) => (
  <Layout>
    <SEO
      title={(data.page && data.page.title) || "Det Vilde Kor"}
      keywords={(data.page && data.page.keywords) || []}
    />
    <Container blocks={(data.page && data.page._rawContent) || []} />
  </Layout>
)

export default GenericPage

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      _rawContent
      title
      keywords
    }
  }
`
